<template>
    <b-col lg="3" md="4" sm="6" class="text-center box p-3">
        <div class="img-container">
            <hearts-animation v-if="triggerHearts"/>
            <b-img-lazy :src="requireSlimeImgSrc(slime)" :alt="slime.nom" fluid/>
            <div class="overlay" v-if="slime.xp >= 100">
                <a id="changeForm" class="icon" title="Changer de forme" @click="changeForm">
                    <font-awesome-icon icon="sync" class="overlay-icon"/>
                </a>
            </div>
        </div>
        <h4 class="mt-2 mb-2">
            {{ getSlimeNom(slime) }}
            <text-edit-button @click.native="renameModal" class="ml-1"/>
        </h4>
        <b-progress :value="slime.xp" :max="100" :variant="barVariant" v-b-tooltip.hover="slime.xp + ' xp'"/>
        <div v-b-tooltip.hover="isHungry(slime) ? null : (getSlimeNom(slime)+' n\'a pas faim pour le moment')">
            <b-button :variant="isHungry(slime) ? 'success' : 'secondary'" :disabled="!isHungry(slime)" @click="feed">
                Donner de la tartiflette
            </b-button>
        </div>
    </b-col>
</template>

<script>
    import {
        requireSlimeImgSrc,
        getType,
        getSlimeNom,
        isHungry,
        momentDates
    }                      from '@/util/slime';
    import {apiPath}       from '@/util/http';
    import alert           from '@/util/alert';
    import HeartsAnimation from "./HeartsAnimation";

    const TextEditButton = () => import('@/components/text_button/TextEditButton');
    const EditText       = () => import('@/components/modals/quick_edit/EditText');

    export default {
        name: "SlimeBlock",
        components: {HeartsAnimation, TextEditButton},
        props: {
            slime: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            slimeType: {},
            triggerHearts: false
        }),
        computed: {
            barVariant() {
                return this.slime.xp < 25 ? 'danger' :
                    this.slime.xp < 50 ? 'warning' :
                        this.slime.xp < 100 ? 'success' :
                            'info';
            }
        },
        methods: {
            requireSlimeImgSrc,
            isHungry,
            getSlimeNom,
            reloadSlime() {
                this.axios.get(apiPath('detail_slime', {slime: this.slime.id}))
                    .then(response => this.slime = momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de recharger les informations du slime'));
            },
            feed() {
                alert.loading();
                this.axios.post(apiPath('feed_slime', {slime: this.slime.id}))
                    .then(() => this.reloadSlime())
                    .then(() => {
                        this.triggerHearts = true;
                        setTimeout(() => this.triggerHearts = false, 5000);
                    })
                    .catch(() => this.$toaster.error('Impossible de nourrir le slime'))
                    .finally(alert.stopLoading);
            },
            renameModal() {
                this.$store.dispatch('modal/create', {
                    component: EditText,
                    props: {
                        value: this.slime.nom,
                        callback: input => {
                            alert.loading();
                            this.axios.post(apiPath('rename_slime', {slime: this.slime.id}), {nom: input})
                                .then(() => this.reloadSlime())
                                .catch(() => this.$toaster.error('Impossible de renommer le slime'))
                                .finally(alert.stopLoading);
                        }
                    }
                });
            },
            changeForm() {
                alert.loading();
                this.axios.post(apiPath('change_slime_form', {slime: this.slime.id}))
                    .then(() => this.reloadSlime())
                    .catch(() => this.$toaster.error("Impossible de changer la forme du slime"))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.slimeType = getType(this.slime.type);
        }
    }
</script>

<style scoped>
    img.img-fluid {
        filter: drop-shadow(0 0 .5rem rgba(0, 0, 0, .66));
        max-width: 128px;
    }

    .box:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    }

    /* Container needed to position the overlay. Adjust the width as needed */
    .img-container {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
    }

    /* The overlay effect (full height and width) - lays on top of the container and over the image */
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .3s ease;
    }

    /* When you mouse over the container, fade in the overlay icon*/
    .img-container:hover .overlay {
        opacity: 0.5;
    }

    /* The icon inside the overlay is positioned in the middle vertically and horizontally */
    .icon {
        color: white;
        font-size: 150px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        filter: drop-shadow(0 0 0.5rem black);
    }

    .overlay-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
    }

    #changeForm {
        cursor: pointer;
        color: white;
    }
</style>
<template>
    <div>
        <font-awesome-icon icon="heart" v-for="i in heartNumber" :key="'icon_' + i" :class="classes"
                           :style="{'--x': coordinates[i], '--y':  coordinates[i + heartNumber], '--s': scales[i]}"/>
    </div>
</template>

<script>
    export default {
        name: "HeartsAnimation",
        data: () => ({
            heartNumber: 20,
            classes: ['heart', 'text-danger'],
            coordinates: [],
            scales: []
        }),
        mounted() {
            for (let i = 0; i < 2 * this.heartNumber; i++) this.coordinates.push((Math.random() * 100) + '%');
            for (let i = 0; i < this.heartNumber; i++) this.scales.push((Math.random() * 25 + 10) + 'px');
            this.$nextTick(() => this.classes.push('moved'));
            setTimeout(() => this.classes.push('faded'), 500);
        }
    }
</script>

<style scoped>
    .heart {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: var(--s);
        transition: all .8s;
    }

    .heart.moved {
        top: var(--x);
        left: var(--y);
    }

    .heart.faded {
        opacity: 0;
    }
</style>